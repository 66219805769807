import React from "react"
import "./index.less"

class PageHeading extends React.Component {
  render() {
    const { children } = this.props

    return <h1 className="page-heading">{children}</h1>
  }
}

export default PageHeading
