import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Image = ({
  wrapperClassName,
  alt,
  src,
  height,
  width,
  effect = "opacity",
}) => (
  <LazyLoadImage
    wrapperClassName={wrapperClassName}
    alt={alt}
    src={src}
    height={height}
    width={width}
    effect={effect}
  />
)

export default Image
